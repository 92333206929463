// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
    --cui-body-font-family: 'Poppins', sans-serif;
}
.btn{
    --cui-btn-color: #fff; 
}
.rightLoginBg{
    background-image: url(../assets/images/bg.jpg);
    background-size: cover;
}
.sidebar {
    --cui-sidebar-bg: #fff;
    --cui-sidebar-nav-title-color: #000;
    --cui-sidebar-nav-link-color: #333;
    --cui-sidebar-nav-link-icon-color: #7d7d7d;
    --cui-sidebar-nav-link-active-color:#9B4DFF;
    --cui-sidebar-nav-link-active-icon-color: #9B4DFF;
    --cui-sidebar-nav-link-hover-color: #9B4DFF;
    --cui-sidebar-nav-link-hover-bg: rgba(0, 0, 0, 0.1);
    --cui-sidebar-nav-link-hover-icon-color: #9B4DFF;
    --cui-sidebar-nav-group-toggle-show-color: rgba(0, 0, 0, 1);
    --cui-sidebar-nav-group-bg: rgba(0, 0, 0, 0.1);
}
.sidebar-nav .nav-group-toggle::after {
    filter: invert(1);
}
.rightContentArea{
    padding: 30px;
}
.headingType1{
    font-size: 36px;
    font-weight: 700;
}
.shortable{
    cursor: pointer;
}
.actionBtnS{
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    li{
        margin-left: 10px;
        cursor: pointer;        
        svg{
            color:  #666;
            width: 18px;
            &:hover{
                color: #000;
            }
        }
    }
}

.rdw-editor-wrapper{
    padding: 10px;
    border: 1px solid #c5c5c5;
    border-radius: 6px;
}
.addGallery {
    border-radius: 10px;
    border: 2px dashed #ababab;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #7000ff;
    aspect-ratio: 1/1;
    transition: all ease-in-out 300ms;
    svg{
        fill: #7000ff;
        width: 25px !important;
        height: 25px !important;
    }
}
.addGallery:hover{
    border-color: #7000ff;
}
#myGallery{
    display: none;
}
.hasError{
    color: red;
    margin-top: 10px;
    &:empty{
        display: none;
    }
}
.customModalImgUp{
    .modal-header{
        border-bottom: none;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .modal-title{
        flex: 1 0 0;
        text-align: center;
    }
}
.custom-tabs{
    ul{
        border-bottom: 1px solid #eee;
        display: flex;
        list-style: none;
        padding-left: 0;
        li{
            cursor: pointer;
            padding: 10px 20px;
            &.active{
                background: #f2f2f2;
            }
            &+li{
                margin-left: 15px;
            }
        }
    }
    
}
.arGalleryImg{
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -5px;
    >li{
        width: 25%;
        -ms-flex: 0  0  25%;
        flex: 0  0  25%;
        padding: 5px;
        img{
            width: 100%;
            display: block;
            aspect-ratio: 1/1;
            object-fit: cover;
            border: 1px solid #ddd;
        }
    }
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; 
    background: rgba(255, 255, 255, 0.6);
    z-index: 999999;
}

.loader {
    width: 50px;
    height: 50px;
    border: 5px solid #4285f4;
    border-top: 5px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
.sidebar-brand {
    --cui-sidebar-brand-bg:#fff;
}



@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}